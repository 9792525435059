import styled from 'styled-components';
import { DropdownButton} from 'react-bootstrap';
import { ColorChangerBasedOnId } from '../../Theme';

 export const StyledDropdownButton = styled(DropdownButton)`
 .btn-primary {
   
    background-color:#F0F0F0;
    border:none;
    color: ${() => ColorChangerBasedOnId().table.filterButton.color};
    box-shadow: ${() => ColorChangerBasedOnId().table.filterButton.boxShadow}
    4.95px 3.95px 3.6px !important;
    font-weight: 600;
    cursor: pointer;
   
    &:hover,&:active{
      
    }
 `;
