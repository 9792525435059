const config = {
  /* IGF-Internal */
  "igfsaasone.igstg.com": {
    domainId: Number(process.env.REACT_APP_IGFINTERNAL_DOMAINID),
    apiEndPoint :process.env.REACT_APP_BACKEND_APP_URL                    // stage 
  },
  "igfsaasuatone.igstg.com": {
    domainId: Number(process.env.REACT_APP_IGFINTERNAL_DOMAINID),
    apiEndPoint :process.env.REACT_APP_IGF_EMPLOYEE_LOGIN_PRODUCTION    // uat 
  },
  "igfpayroll.impactgurufoundation.org": {
    domainId: Number(process.env.REACT_APP_IGFINTERNAL_DOMAINID),
    apiEndPoint : process.env.REACT_APP_IGF_EMPLOYEE_LOGIN_PRODUCTION    // production
  },

  /* Rebel */
    "igfsaas.igstg.com": {
    domainId: Number(process.env.REACT_APP_REBEL_DOMAINID),
    apiEndPoint :process.env.REACT_APP_HOME_URL                      // stage
  },
  "igfsaasuat.igstg.com": {
    domainId: Number(process.env.REACT_APP_REBEL_DOMAINID),
    apiEndPoint : process.env.REACT_APP_REBEL_EMPLOYEE_LOGIN_PRODUCTION   //uat 
  },
 
  "sparsh-rebelfoods.org": {
    domainId: Number(process.env.REACT_APP_REBEL_DOMAINID),
    apiEndPoint : process.env.REACT_APP_REBEL_EMPLOYEE_LOGIN_PRODUCTION // production
  },

  // Add more URL mappings as needed
};

const currentDomain = window.location.hostname;
// const currentDomain = "igfpayroll.impactgurufoundation.org";
// const currentDomain = "igfsaas.igstg.com";
// Lookup the current domain in the config object
const domainConfig = config[currentDomain];

// Use domainConfig or set default values if not found
const domainId = domainConfig ? domainConfig.domainId : 0;
const apiUrldomain=domainConfig?.apiEndPoint
 console.log(domainConfig)
export { domainId, currentDomain ,apiUrldomain};
