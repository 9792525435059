import React from 'react';

import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

const NotFound = () => {
    const navigate=useNavigate()
  return (
    <NotFoundContainer>
    <Heading>Need To Login</Heading>
    <Button variant="primary"  onClick={()=>{navigate("/")}} style={{width:"auto"}}>Go To Login Page</Button>

  </NotFoundContainer>
  );
};


const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(
    32deg,
    rgba(175, 225, 254, 0.5271358543417367) 0%,
    rgba(255, 255, 255, 0.5019257703081232) 36%,
    rgba(255, 255, 255, 0.48511904761904767) 68%,
    rgba(175, 225, 254, 0.5327380952380952) 100%
  );
`;

const Heading = styled.h1`
  text-align: left;
  font: normal normal bold 37px/40px Roboto;
  letter-spacing: 0px;
  color: #000000;
  text-transform: capitalize;
  opacity: 1;
`;


export default NotFound;