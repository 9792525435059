import React, { useState } from "react";
import { SaveButton, CancelButton } from "./ModelStyle";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import * as Yup from "yup"; // Import Yup
import { DataService } from "../Services/Dataservice";
import NgomodelFormModal from "./NgoModel";  
import { toast } from "react-toastify";
import swal from "sweetalert";

const NgosModel = ({show, setShow, typeCampaigns, callApi}) => {
  const [ngoData, setNgodata] = useState({type : typeCampaigns});
  const [uuid, setUuid] = useState(null);
  const [showcamp, setShowcamp] = useState(false);
  const token = localStorage.getItem("token");

  const handleClose = () => {
    setShow(false);
  };

  const handleSelectChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setNgodata((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const CreateCampaignApi = (token, formData) => {
    const newCampaign = DataService.CreateCampaign(token, formData);

    toast.promise(newCampaign, {
      pending: "Waiting for the response",
    })
    .then((response) => {
      if (response.status === 200) {
        toast.success(response?.data?.message);
        setUuid(response?.data?.uuid);
        swal({
          icon: "success",
          title: response?.data?.message,
          timer: 2000,
          showConfirmButton: false,
        });
        // setShowcamp(true);
        setShow(false)
      } else {
        toast.info(response?.data?.message);
        setShow(false)
      }
    })
    .catch((error) => {
      toast.error("Something went wrong");
    });
  };

  const handleSubmit = async () => {
    try {
      const validatedData = await schema.validate(ngoData, { abortEarly: false });
      CreateCampaignApi(token, validatedData);
      
      // Clear errors on successful form submission
      setError({});
    } catch (validationErrors) {
      const errors = {};
      validationErrors.inner.forEach(error => {
        errors[error.path] = error.message;
      });
      setError(errors);
    }
  };
  

  const schema = Yup.object().shape({
    ngoName: Yup.string().required("Campaigner Name is required"),
    ngoEmail: Yup.string().email("Invalid email format").required("Campaigner Email is required"),
    ngoMobile: Yup.string()
      .matches(/^\d+$/, "Campaigner Number should contain only numbers")
      .required("Campaigner Number is required"),
    campaignTitle: Yup.string()
      .matches(/^[a-zA-Z\s]+$/, "Campaign Title should contain only letters")
      .required("Campaign Title is required"),
    NGO_Name: Yup.string()
      .matches(/^[a-zA-Z\s]+$/, "NGO Name should contain only letters")
      .required("NGO Name is required"),
    ngoCity: Yup.string()
      .matches(/^[a-zA-Z\s]+$/, "NGO City should contain only letters")
      .required("NGO City is required"),
    goalAmt: Yup.string()
      .matches(/^\d+$/, "Goal Amount should contain only numbers")
      .required("Goal Amount is required"),
  });
  
  const [error, setError] = useState({});
  return (
    <div>
      <Container style={{ backgroundColor: "#EDF9FF !important" }}>
        <Modal show={show} onHide={handleClose} style={{ padding: "1rem" }}>
          <div className="p-4">
            <h>Add Campaign details</h>
            <br />
            <br />
            <Form>
              <div className="row">
                <div className="col-12">
                  <Form.Group
                    className="mb-1"
                    controlId="EmployeeModelForm.ControlInput1"
                  >
                    <Form.Control
                      type="Name"
                      placeholder="Campaigner Name"
                      name="ngoName"
                      autoFocus
                      value={ngoData.ngoName}
                      onChange={handleSelectChange}
                      className="input-bg mt-2"
                    />
                     {error.ngoName && (
                      <span style={{ color: "red", fontSize: "10px" }}>{error.ngoName}</span>
                    )}
                  </Form.Group>
                
                </div>
                <div className="col-12">
                  <Form.Group
                    className="mb-1"
                    controlId="EmployeeModelForm.ControlInput1"
                  >
                    <Form.Control
                      type="Name"
                      placeholder="Campaigner Email"
                      name="ngoEmail"
                      autoFocus
                      value={ngoData.ngoEmail}
                      onChange={handleSelectChange}
                      className="input-bg mt-2"
                    />
                     {error.ngoEmail && (
                    <span style={{ color: "red", fontSize: "10px" }}>
                      {error.ngoEmail}
                    </span>
                  )}
                  </Form.Group>
                 
                </div>

                <div className="col-12">
                  <Form.Group
                    className="mb-1"
                    controlId="  EmployeeModelForm.ControlInput1"
                  >
                    <Form.Control
                      type="tek"
                      placeholder="Campaigner Number"
                      name="ngoMobile"
                      autoFocus
                      value={ngoData.ngoMobile}
                      onChange={handleSelectChange}
                      className="input-bg mt-2"
                      maxLength={10}
                    />
                    {error.ngoMobile && (
                    <span style={{ color: "red", fontSize: "10px" }}>
                      {error.ngoMobile}
                    </span>
                  )}
                  </Form.Group>
                  
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <Form.Group
                    className="mb-1"
                    controlId="EmployeeModelForm.ControlInput1"
                  >
                    <Form.Control
                      type="name"
                      placeholder="Campaign Title"
                      name="campaignTitle"
                      autoFocus
                      value={ngoData.campaignTitle}
                      onChange={handleSelectChange}
                      className="input-bg mt-2"
                      max={99}
                      min={1}
                    />
                    {error.campaignTitle && (
                    <span style={{ color: "red", fontSize: "10px" }}>
                      {error.campaignTitle}
                    </span>
                  )}
                  </Form.Group>
                  
                </div>
                <div className="col-12">
                  <Form.Group
                    className="mb-1"
                    controlId="  EmployeeModelForm.ControlInput1"
                  >
                    <Form.Control
                      type="Name"
                      placeholder="NGO Name"
                      name="NGO_Name"
                      autoFocus
                      value={ngoData.NGO_Name}
                      onChange={handleSelectChange}
                      className="input-bg mt-2"
                    />
                     {error.NGO_Name && (
                    <span style={{ color: "red", fontSize: "10px" }}>
                      {error.NGO_Name}
                    </span>
                  )}
                  </Form.Group>
                 
                </div>
                <div className="col-12">
                  <Form.Group
                    className="mb-1"
                    controlId="  EmployeeModelForm.ControlInput1"
                  >
                    <Form.Control
                      type="Name"
                      placeholder="NGO City"
                      name="ngoCity"
                      autoFocus
                      value={ngoData.ngoCity}
                      onChange={handleSelectChange}
                      className="input-bg mt-2"
                    />
                    {error.ngoCity && (
                    <span style={{ color: "red", fontSize: "10px" }}>
                      {error.ngoCity}
                    </span>
                  )}
                  </Form.Group>
                  
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <Form.Group
                    className="mb-1"
                    controlId="  EmployeeModelForm.ControlInput1"
                  >
                    <Form.Control
                      type="Name"
                      placeholder="Goal Amount"
                      name="goalAmt"
                      autoFocus
                      value={ngoData.goalAmt}
                      onChange={handleSelectChange}
                      className="input-bg mt-2"
                    />
                     {error.goalAmt && (
                    <span style={{ color: "red", fontSize: "10px" }}>
                      {error.goalAmt}
                    </span>
                  )}
                  </Form.Group>
                 
                </div>
              </div>
            </Form>
          </div>

          <Modal.Footer>
            <SaveButton
              variant="primary"
              className="save-Button"
              onClick={handleSubmit}
            >
              Submit
            </SaveButton>
            <CancelButton
              variant="secondary"
              onClick={handleClose}
              className="cancel-button"
            >
              Cancel
            </CancelButton>
          </Modal.Footer>
        </Modal>
      </Container>

      

        {typeCampaigns == 1 &&  <NgomodelFormModal
            show={showcamp}
            setShow={setShowcamp}
            uuid={uuid}
            callApi={callApi}
          />}
    </div>
  );
};

export default NgosModel;
