import styled, { css } from "styled-components";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { NavLink } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { ColorChangerBasedOnId } from "../../Theme";

// Media queries
const mobileMedia = "max-width: 767px";
const tabletMedia = "min-width: 600px";

// Create a media query mixin
const media = {
  mobile: (...args) => css`
    @media (${mobileMedia}) {
      ${css(...args)};
    }
  `,
  tablet: (...args) => css`
    @media (${tabletMedia}) {
      ${css(...args)};
    }
  `,
};

export const StyledNavbar = styled(Navbar)`
  box-shadow: 1px -4px 11px 0px;
  background-color: ${(state) =>
    ColorChangerBasedOnId().header.backgroundColor};
  .imgicon {
    width: 35%;
  }
  .clock-logo {
    font-size: 20px;
    color: #ffffff;
    margin: 0 2px 1px;
  }
  
`;

export const Headerlogo = styled.img`
  width: 35%;
  @media (max-width: 767px) {
    .imgicon {
      width: 81% !important;
      height: 38px !important;
    }
  }
`;
export const StyledOffcanvasHeader = styled(Offcanvas.Header)`
  background-color: ${(state) =>
    ColorChangerBasedOnId().header.backgroundColor};
`;

const StyledNavLink = styled(NavLink)`
  /* Define your styles for NavLink here */
`;

export const ToggelButton = styled(Button)`
  width: 5rem;
  left: 30px;
  background: ${(state) =>
    ColorChangerBasedOnId().header.button.backgroundColor};
  color: ${(state) => ColorChangerBasedOnId().header.button.color};
  border: none;

  ${media.mobile`
 width: 5rem;
 left: 30px;
 background: #15729c;
 color: #ffffff;
 border: none;
 margin-top: 1rem;
  `}
`;
