import React from "react";

import igfBanner from "../../../Images/igf-banner.png";
import styled from "styled-components";

const HomePageStyled = styled.div`
  .home-page {
    background-image: url(${igfBanner});
    background-size: 100% 100%;
    height: 43rem;
    width: 100%;
  }
  .text-box {
    padding-top: 16rem;
    padding-left: 3rem;
    font-size: 21px;
    text-align: left;
    font: normal normal normal 20px/72px Roboto;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;

    .name-bold {
      font: normal normal normal 80px/26px Roboto;
      font-weight: bold;
    }

    .sub-text {
      font: normal normal normal 15px/20px Roboto;
    }
  }

  img {
    height: 100vh;
    width: 100%;
    display: block;
  }

  img::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 50%; /* Adjust the width as needed */
    height: 100%;
    backdrop-filter: blur(10px); /* Adjust the blur radius as needed */
    z-index: 1;
  }

  @media (max-width: 600px) {
    .text-box {
      font-size: 21px;
      text-align: left;
      font: normal normal normal 20px/26px Roboto;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
      padding: 36px;
      margin-top: 4rem;
    }

    img {
      height: 100%;
      width: inherit;
    }
  }

  img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
`;
const IgfHomePage = () => {
  return (
    <HomePageStyled>
      <div className="container-fluid home-page">
        <div className="row">
          <div className="col-lg-5 col-sm-12 text-box ">
            <span className="name-bold">Impact Guru Foundation</span>

            <div className="sub-text">
              {" "}
              Impact Guru Foundation was founded in 2014, to provide affordable
              and accessible healthcare to all. Through our interventions, we
              have defined our vision, mission and values to help inform our
              programmes going forward. Deriving on the principles of healing,
              health, harmony and happiness, we have pivoted our approach to
              reflect high standards of delivery of healthcare services to
              all,to realize our dream of a #HealthyBharatHappyBharat
            </div>

            <br />
          </div>
          <div className="col-lg-7 col-sm-12 p-0  imgclass">
            {/* <img src={igfBanner} alt="" /> */}
          </div>
        </div>
      </div>
    </HomePageStyled>
  );
};

export default IgfHomePage;
