import { domainId } from "./DomainVariable";
import igf from "./Images/Igf-Logo-header.png";
import rebelIcon from "./Images/Rebel-Logo-header.png";
import igfHeadericon from "./Images/Igf-Logo-header.png";
import rebelHeadericon from "./Images/Rebel-mainHeader-logo.png";

const theme = {
  themeColor2: "#F8F8F8",
  themeColor1: "#FFFFFF",
  themeColor3: "#EDF9FF",
  themeColor4: "#15729C",
  themeColor41: "#1f935d",
  themeColor5: "#316C4E",
  themecard: "#83deb0",
  footerIG: "rgb(218 216 216)",
  themeFont: "Roboto",
  colors: {
    white: "#ffffff",
    black: "#000",
    blue: "#15729C",
    blue1: "#EDF9FF",
    blue2: "#2486B2",
    colorGray: "#8B8B8B",
    colorGray2: "#64748B",
    colorGray3: "#f6f6f6",
    colorGray4: "#f6fafd",
    color5: "#3887ab",
    cancelGray: "#565e64",
  },
};

export default theme;

// Ui Change Based on Domain Id
export const ColorChangerBasedOnId = () => {
  
  switch (domainId) {
    case 2:
      return {
        header: {
          backgroundColor: theme.colors.white,
          color: "",
          button: {
            backgroundColor: theme.themeColor41,
            color: theme.colors.white,
          },
        },
        sidebar: {
          backgroundColor: theme.themeColor41,
          color: theme.colors.white,
          activeColor: theme.themeColor5,
        },
        footer: { backgroundColor: theme.footerIG, color: theme.colors.white },
        table: {
          th: {
            backgroundColor: theme.themeColor41,
            color: theme.colors.white,
          },
          icone: {
            color: theme.themeColor41,
          },
          addButton: {
            backgroundColor: theme.themeColor41,
            color: "#ffffff",
          },
          filterButton: {
            color: theme.themeColor41,
            boxShadow: theme.themeColor41,
          },
          campignButton: {
            backgroundColor: theme.themeColor41,
          },
        },
        pagination: {
          backgroundColor: theme.colors.white,
          color: theme.themeColor41,
          borderColor: theme.themeColor41,
        },
        Cards: {
          backgroundColor: theme.themecard,
          color: theme.colorGray,
          icone: {
            color: theme.colors.white,
          },
        },
        model: {
          saveButton: {
            backgroundColor: theme.themeColor41,
          },
          cancelButton: {
            backgroundColor: theme.colors.cancelGray,
          },
          sampleDownload: {
            color: theme.themeColor41,
          },
          header: {
            backgroundColor: theme.themeColor41,
          },
        },
        loginPage: {
          loginContainer: {
            backgroundColor: theme.themeColor5,
          },
          loginForm: {
            backgroundColor: `radial-gradient(#ffffff, transparent)`,
          },
          input: {
            backgroundColor: "#56816d",
          },
          button: {
            backgroundColor: "#ffffff",
            color: theme.colors.color5,
          },
        },
        forgotPage: {
          forgotContainer: {
            backgroundColor: theme.themeColor5,
          },
        },
      };
    default:
      return {
        header: {
          backgroundColor: theme.themeColor4,
          color: "",
          button: {
            backgroundColor: theme.colors.white,
            color: theme.themeColor4,
          },
        },
        sidebar: {
          backgroundColor: theme.themeColor3,
          color: theme.colors.colorGray,
          activeColor: theme.themeColor4,
          collapes: theme.colors.white,
        },
        footer: {
          backgroundColor: theme.themeColor4,
          color: theme.colors.black,
        },
        table: {
          th: {
            backgroundColor: theme.themeColor3,
            color: theme.themeColor4,
          },
          icone: {
            color: theme.themeColor4,
          },
          addButton: {
            backgroundColor: theme.themeColor4,
            color: "#ffffff",
          },
          filterButton: {
            color: theme.themeColor4,
            boxShadow: theme.themeColor4,
          },
          campignButton: {
            backgroundColor: theme.themeColor4,
          },
        },
        pagination: {
          backgroundColor: theme.colors.white,
          color: theme.themeColor4,
          borderColor: theme.themeColor4,
        },
        Cards: {
          backgroundColor: theme.themeColor4,
          color: theme.colorGray,
          icone: {
            color: theme.colors.white,
          },
        },
        model: {
          saveButton: {
            backgroundColor: theme.themeColor4,
          },
          cancelButton: {
            backgroundColor: theme.colors.cancelGray,
          },
          sampleDownload: {
            color: theme.themeColor4,
          },
          header: {
            backgroundColor: theme.themeColor3,
          },
        },
        loginPage: {
          loginContainer: {
            backgroundColor: theme.themeColor4,
          },
          loginForm: {
            backgroundColor: "radial-gradient(#15729c, transparent)",
          },
          input: {
            backgroundColor: theme.colors.color5,
          },
          button: {
            backgroundColor: "theme.colors.blue",
            color: theme.colors.blue,
          },
        },
        forgotPage: {
          forgotContainer: {
            backgroundColor: theme.themeColor4,
          },
          forgotForm: {
            backgroundColor: "radial-gradient(#15729c, transparent)",
          },
          input: {
            backgroundColor: theme.colors.color5,
          },
          button: {
            backgroundColor: "theme.colors.blue",
            color: theme.colors.blue,
          },
        },
      };
  }
};


// Header icone change based on domainId
export function getHeaderImageBasedOnInput(inputValue) {
  let imageSource;
  // Determine the image source based on the input value
  switch (inputValue) {
    case 1:
      imageSource = rebelIcon;
      break;
    case 2:
      imageSource = igf;
      break;
    default:
      imageSource = "/default-image.png"; // Provide a default image for other values
  }

  return imageSource;
}


// Header icone change based on domainId
export function getMainHeaderImageBasedOnInput(inputValue) {
  let imageSource;
  // Determine the image source based on the input value
  switch (inputValue) {
    case 1:
      imageSource = rebelHeadericon;
      break;
    case 2:
      imageSource = igfHeadericon;
      break;
    default:
      imageSource = "/default-image.png"; // Provide a default image for other values
  }

  return imageSource;
}