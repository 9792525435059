import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { SaveButton, CancelButton } from "./ModelStyle";
import { DataService } from "../Services/Dataservice";
import swal from "sweetalert";
import styled from "styled-components";
import theme from "../Theme";
import { ColorChangerBasedOnId } from "../Theme";

const EmployeeModelStyle = styled(Modal)`
  .modal-header {
    background: ${(state) =>
      ColorChangerBasedOnId().model.header.backgroundColor};
  }

  .red {
    color: red;
  }
`;

function EmployeeModel(props) {
  const { show, setShow, data, setCallApi, callApi } = props;
  const [user, setUser] = useState(data);
  const handleClose = () => setShow(false);
  const [error, setError] = useState({});
  const token = localStorage.getItem("token");

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    console.log(name, value);
    let errorMessage = "";

    // Perform validation based on the input field name
    switch (name) {
      case "full_name":
        errorMessage = value.trim() ? "" : "Name is required";
        break;
      case "email":
        errorMessage = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)
          ? ""
          : "Invalid email address";
        break;
      case "mobile":
        errorMessage = /^\d{10}$/.test(value) ? "" : "Invalid mobile number";
        break;
      case "pan_num":
        errorMessage = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(value)
          ? ""
          : "Invalid PAN number";
        break;
      case "zipcode":
        errorMessage = /^\d{6}$/.test(value) ? "" : "Invalid zipcode";
        break;

      case "employee_code":
        errorMessage = value.trim() ? "" : "Employee Code is required";
        break;
      case "address":
        errorMessage = value.trim() ? "" : "Address is required";
        break;
      case "gender":
        errorMessage = value ? "" : "Gender is required";
        break;

      case "city":
        errorMessage = value.trim() ? "" : "City is required";
        break;

      case "state":
        errorMessage = value.trim() ? "" : "State is required";
        break;
      case "date_of_birth":
        errorMessage = value ? "" : "Date of Birth is required";
        break;
      case "status":
        errorMessage = value ? "" : "Status is required";
        break;
      default:
        break;
    }

    // Update the state with the new value and error message
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));

    setError((previous) => ({
      ...previous,
      [name]: errorMessage,
    }));
  };

  const handleSave = () => {
    // if (Object.values(error).length === 0) {

    StatusChangeApi(token, user);
    handleClose();
    // }
  };

  const StatusChangeApi = (token, data) => {
    DataService.StatusChange(token, data)
      .then((res) => {
        if (res.status === 200) {
          setCallApi(!callApi);
          swal({
            icon: "success",
            title: `${res.data.message}`,
            timer: 2000,
            showConfirmButton: false,
          });
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: "User details not updated!",
          timer: 2000,
          showConfirmButton: false,
        });
      });
  };

  useEffect(() => {
    setUser(data);
  }, [data]);

  return (
    <>
      <EmployeeModelStyle show={show} onHide={handleClose} id="EmployeeModel">
        <Modal.Header closeButton>
          <Modal.Title>Edit employee details</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-2">
          <Form className="p-3">
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>
                Name{" "}
                <span>
                  <span className="red">*</span>
                </span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                name="full_name"
                value={user.full_name}
                onChange={handleInputChange}
                isInvalid={!!error.full_name}
                required
              />
              <Form.Control.Feedback type="invalid">
                {error.full_name}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="email">
              <Form.Label>
                Email{" "}
                <span>
                  <span className="red">*</span>
                </span>
              </Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                name="email"
                value={user.email}
                onChange={handleInputChange}
                isInvalid={!!error.email}
                required
              />
              <Form.Control.Feedback type="invalid">
                {error.email}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="mobile">
              <Form.Label>
                Mobile Number{" "}
                <span>
                  <span className="red">*</span>
                </span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter mobile number"
                name="mobile"
                value={user.mobile}
                onChange={handleInputChange}
                isInvalid={!!error.mobile}
                required
              />
              <Form.Control.Feedback type="invalid">
                {error.mobile}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="employeeCode">
              <Form.Label>
                Employee Code{" "}
                <span>
                  <span className="red">*</span>
                </span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter employee code"
                name="employee_code"
                value={user.employee_code}
                onChange={handleInputChange}
                isInvalid={!!error.employee_code}
                required
              />
              <Form.Control.Feedback type="invalid">
                {error.employee_code}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="address">
              <Form.Label>
                Address{" "}
                <span>
                  <span className="red">*</span>
                </span>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter address"
                name="address"
                value={user.address}
                onChange={handleInputChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                {error.address}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="gender">
              <Form.Label>
                Gender{" "}
                <span>
                  <span className="red">*</span>
                </span>
              </Form.Label>
              <Form.Select
                aria-label="Gender"
                name="gender"
                value={user.gender}
                onChange={handleInputChange}
                required
              >
                <option value={0}>Male</option>
                <option value={1}>Female</option>
                <option value={2}>Other</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {error.gender}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="pan">
              <Form.Label>
                PAN{" "}
                <span>
                  <span className="red">*</span>
                </span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter PAN"
                name="pan_num"
                value={user.pan_num}
                onChange={handleInputChange}
                isInvalid={!!error.pan_num}
                required
              />
              <Form.Control.Feedback type="invalid">
                {error.pan_num}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="city">
              <Form.Label>
                City{" "}
                <span>
                  <span className="red">*</span>
                </span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter city"
                name="city"
                value={user.city}
                isInvalid={!!error.city}
                onChange={handleInputChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                {error.city}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="zipcode">
              <Form.Label>
                Zipcode{" "}
                <span>
                  <span className="red">*</span>
                </span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter zipcode"
                name="zipcode"
                value={user.zipcode}
                onChange={handleInputChange}
                isInvalid={!!error.zipcode}
                required
              />
              <Form.Control.Feedback type="invalid">
                {error.zipcode}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="dob">
              <Form.Label>Date of Birth</Form.Label>
              <Form.Control
                type="date"
                placeholder="Enter date of birth"
                name="date_of_birth"
                value={user.date_of_birth}
                isInvalid={!!error.date_of_birth}
                onChange={handleInputChange}
              />
              <Form.Control.Feedback type="invalid">
                {error.date_of_birth}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="state">
              <Form.Label>
                State{" "}
                <span>
                  <span className="red">*</span>
                </span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter state"
                name="state"
                value={user.state}
                onChange={handleInputChange}
                isInvalid={!!error.state}
                required
              />
              <Form.Control.Feedback type="invalid">
                {error.state}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="status">
              <Form.Label>
                Status{" "}
                <span>
                  <span className="red">*</span>
                </span>
              </Form.Label>
              <Form.Select
                aria-label="Status"
                name="status"
                value={user.status}
                onChange={handleInputChange}
                isInvalid={!!error.status}
                required
              >
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {error.status}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <CancelButton
            variant="secondary"
            onClick={handleClose}
            className="close-button"
          >
            Close
          </CancelButton>
          <SaveButton
            variant="primary"
            onClick={handleSave}
            className="save-button"
          >
            Save Changes
          </SaveButton>
        </Modal.Footer>
      </EmployeeModelStyle>
    </>
  );
}

export default EmployeeModel;
