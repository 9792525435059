import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { CardBody } from "./CardStyle";
import { DataService } from "../Services/Dataservice";
import { toast } from "react-toastify";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import {
 
  FilterButton
} from "../Common/tableStyled";

// ... (previous imports)

const FundRisedCard = () => {
  const [duration, setDuration] = useState(1);
  const [fundData, setFundData] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false); // New state to track loading state

  const token = localStorage.getItem("token");

  const handleChange = (e) => {
    setDuration(e.target.value);
  };

  const handleDateChange = (e, type) => {
    const dateValue = e.target.value;
    if (type === "start") {
      setStartDate(dateValue);
    } else if (type === "end") {
      setEndDate(dateValue);
    }
  };

  const TotalFundRisedApi = (duration, startDate, endDate, token) => {
    setLoading(true); // Set loading state to true
    const totalFundRised = DataService.TotalFundRaised(
      duration,
      startDate,
      endDate,
      token
    );
    toast
      .promise(totalFundRised, {})
      .then((res) => {
        if (res?.status === 200) {
          setFundData(res?.data?.data);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch(() => {
        toast.error("Something went wrong");
      })
      .finally(() => {
        setLoading(false); // Set loading state to false after API call
      });
  };

  const handleSubmit = () => {
  
    TotalFundRisedApi(Number(duration), startDate, endDate, token);
  };

   useEffect(()=>{
    TotalFundRisedApi(Number(duration), startDate, endDate, token);
   },[])
  return (
    <>
      <CardBody>
        <div className="d-flex justify-content-start p-lg-3">
          <Card className="pr-lg-2 mr-lg-3 cardeFund">
            <Card.Body>
              <div className="d-flex" style={{ height: "100%" }}>
                <div style={{ flex: "40%" }}>
                  <h className="heading">Funds</h>
                  <div className="d-flex">
                    <div className="moneyicondiv">
                      <FaRegMoneyBillAlt className="moneyicon" />
                    </div>
                    <div>
                      <h className="count">{fundData}</h>
                    </div>
                  </div>
                </div>
                <div style={{ flex: "60%" }}>
                  <Form.Select
                    aria-label="Default select EmployeeModel"
                    className="formselect mb-2"
                    value={duration}
                    onChange={handleChange}
                  >
                    <option value={1}>Total fund Amt</option>
                    <option value={2}>Withdrawable Amt</option>
                  </Form.Select>
                  <div className="d-flex">
                    <Form.Group className="mb-2 d-flex flex-wrap " style={{marginRight:"10px",maxWidth:"116px"}}>
                      <Form.Label htmlFor="startDate">Start Date</Form.Label>
                        
                      <Form.Control
                        type="date"
                        id="startDate"
                        name="startDate"
                        className="form-control fundCalender"
                        value={startDate}
                        onChange={(e) => handleDateChange(e, "start")}
                        placeholder="start date"
                        max={new Date().toISOString().split("T")[0]}
                      />
                    </Form.Group>
                    <Form.Group className="mb-2 d-flex flex-wrap " style={{marginRight:"10px",maxWidth:"116px"}}>
                      <Form.Label htmlFor="endDate">End Date</Form.Label>
                      <Form.Control
                        type="date"
                        id="endDate"
                        name="endDate"
                        className="form-control fundCalender"
                        value={endDate}
                        onChange={(e) => handleDateChange(e, "end")}
                        placeholder="End date"
                        max={new Date().toISOString().split("T")[0]}
                      />
                    </Form.Group>
                  </div>
                  <div className="d-flex justify-content-end">
                    <FilterButton
                      type="button"
                      className="btn  mt-2 fundButton"
                      onClick={handleSubmit}
                      disabled={loading} // Disable the button when the API is loading
                    >
                      {loading ? "Loading..." : "Submit"}
                    </FilterButton>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </CardBody>
    </>
  );
};

export default FundRisedCard;
