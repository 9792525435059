import styled from "styled-components";
import { ColorChangerBasedOnId } from "../../Theme";

// Styled components

export const ForgotContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: ${() =>
    ColorChangerBasedOnId().forgotPage.forgotContainer.backgroundColor};
`;
export const ForgotForm = styled.div`

  width: 340px;
  padding: 20px;
  background-color: #f2f2f2;
  border-radius: 5px;
  background: ${(state) =>
    ColorChangerBasedOnId().forgotPage.forgotForm.backgroundColor};
    color:white;
`;
export const Input = styled.input`
  width: 100%;
  padding: 5px;
  border-radius: 3px;
  border: 1px solid #ccc;
  box-shadow:4px 4px 1px 1px rgba(0,0,0,0.5);
  background: ${(state) =>
    ColorChangerBasedOnId().forgotPage.input.backgroundColor};
  color: #ffffff;
  &:active,&:focus{
    border:none;
    outline:none;
  };
  &::placeholder {
    color: #ffffff; /* Set the desired color */
  }
`;
export const OtpInput=styled.input`
width: 100%;
padding: 5px;
height:29px;
border-radius: 3px;
border: 1px solid #ccc;
box-shadow:4px 4px 1px 1px rgba(0,0,0,0.5);
text-align: center;
background: ${(state) =>
  ColorChangerBasedOnId().forgotPage.input.backgroundColor};
color: #ffffff;
&:active,&:focus{
  border:none;
  outline:none;
};
&::placeholder {
  color: #ffffff; /* Set the desired color */
}
`;
export const Image = styled.img`
  width: 100%;
`;
export const Button = styled.button`
  display: block;
  width: 100%;
  padding: 10px;
  background-color: ${(state) =>
    ColorChangerBasedOnId().forgotPage.button.backgroundColor};
  color: ${(state) => ColorChangerBasedOnId().forgotPage.button.color};
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  margin-top: 1rem;
`;
