import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import theme from "../../Theme";
import { ColorChangerBasedOnId } from "../../Theme";

// Styled component for .sidebar
export const Sidebar = styled(motion.div)`
  background: ${() => ColorChangerBasedOnId().sidebar.backgroundColor};

  width: 200px;
  transition: all 0.5s;
  position: relative;
  height: auto;
  @media (max-width: 768px) {
    display: none;
  }
`;

// Styled component for .link
export const StyledNavLink = styled(NavLink)`
  display: flex;
  color: ${() => ColorChangerBasedOnId().sidebar.color};
  padding: 10px 15px;
  gap: 15px;
  transition: all 0.5s;
  text-decoration: none;

  &:hover {
    transition: all 0.5s;
  }

  &.active {
    color: ${() => ColorChangerBasedOnId().sidebar.activeColor};
    font-size: 90px;
    transition: all 0.5s;
  }

  .icon,
  .link_text {
    font-size: 20px;
  }
`;

export const TopSection = styled.div`
  font-size: 1rem;
  .bars {
    cursor: pointer;
    font-size: 21px;
    color: ${() => ColorChangerBasedOnId().header.TopSection};
    .collapes {
      padding: 4px 4px 0 1rem;
      .collapes-text {
        font-size: 17px;
        margin-left: 1rem;
      }
    }
  }
`;
