import React, { useEffect } from "react";
import styled from "styled-components";
import arrow from "./images/toparrow.png";
import { useSelector } from "react-redux";

const ProgressContainer = styled.div`
  position: fixed;
  bottom: 100px;
  right: 30px;
  display: none;
  width: 40px;
  height: 40px;
  background: "";
  border-radius: 50%;
  cursor: pointer;
  z-index: 999;
  transition: background 0.3s ease; /* Add a smooth transition for the background color */

  &:hover {
    background: #488dcb; /* Change the background color on hover */
  }
`;

const ProgressValue = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #fff;
`;

const ProgressImg = styled.img`
  width: 20px;
  height: 20px;
`;

const GotoTop = () => {
  let calcScrollValue = () => {
    let pos = document.documentElement.scrollTop;
    let calcHeight =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    let scrollValue = Math.round((pos * 100) / calcHeight);
    const scrollProgress = document.getElementById("progress");

    if (scrollProgress) {
      // Check if the element exists before manipulating it
      if (pos > 100) {
        scrollProgress.style.display = "grid";
      } else {
        scrollProgress.style.display = "none";
      }

      scrollProgress.addEventListener("click", () => {
        document.documentElement.scrollTop = 0;
      });

      scrollProgress.style.background = `conic-gradient(rgb(88 94 91) ${scrollValue}%, #ffffff ${scrollValue}%)`;
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", calcScrollValue);
    window.addEventListener("load", calcScrollValue);

    return () => {
      window.removeEventListener("scroll", calcScrollValue);
      window.removeEventListener("load", calcScrollValue);
    };
  }, []);

  return (
    <ProgressContainer id="progress">
      <ProgressValue id="progress-value">
        <ProgressImg src={arrow} alt="Scroll to Top" />
      </ProgressValue>
    </ProgressContainer>
  );
};

export default GotoTop;
