import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { saveToken } from "../../Store/loginSlice";
import { DataService } from "../../Services/Dataservice";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import getImageBasedOnInput from "../../Common/ImageHandler/ImageHandler";
import {
  LoginContainer,
  LoginForm,
  Input,
  Button,
  Error,
  Image,
  ForgotText
} from "./loginStyle";
import { useSelector } from "react-redux";
const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const domainId = useSelector((State) => State.DomainDetails.domainId);
  const currentDomain = useSelector((State) => State.DomainDetails.currentDomain);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const loginFunction = (formdata) => {
    const tokenData = DataService.LoginUser(formdata);

    toast
      .promise(tokenData, {
        pending: "Waiting for the respose",
      })

      .then((res) => {
        if (res.status == 201 || res.status == 200) {
          toast.success("API request successful");
          localStorage.setItem("token", res.data.token);
          navigate("/");
          dispatch(saveToken(res.data.token));
        } else {
          toast.error(res.response.data.message);
        }
      })
      .catch((error) => {
        if (error) {
       
          toast.error(error.message);
        }
      });
  };
  const handleForgotPassword = () => {
    navigate("/forgot-password");
  }
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!email || !password) {
      setError("Please enter both email and password.");
    } else if (!regex.test(email)) {
      setError("Enter valid Email Address!");
    } else {
      const formdata = {
        email,
        password,
        domain: currentDomain,
      };
      loginFunction(formdata);
      setError("");
    }
  };

  return (
    <div>
      <LoginContainer>
        <LoginForm>
          <form onSubmit={handleSubmit}>
            <Image src={getImageBasedOnInput(domainId)} alt="" />

            <div className="form-group pt-1">
             
              <Input
                type="email"
                id="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Email"
              />
            </div>
            <div className="form-group pt-2">
             
              <Input
                type="password"
                id="password"
                value={password}
                onChange={handlePasswordChange}
                placeholder="Password"
              />
            </div>
            <Error>{error && <p className="error">{error}</p>}</Error>
            <Button type="submit">Login</Button>
           {<ForgotText onClick={handleForgotPassword}>Forgot Password :</ForgotText> } 
          </form>
          <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </LoginForm>
      </LoginContainer>
    </div>
  );
};

export default Login;
