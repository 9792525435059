import { ColorChangerBasedOnId } from "../Theme";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import { useState } from "react";
import { DataService } from "../Services/Dataservice";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { saveAs } from "file-saver";
import file from "./payroll-demofile.csv";
import styled from "styled-components";
import { SaveButton, CancelButton } from "./ModelStyle";
import theme from "../Theme";

const FileUpload = ({ show, setShow, Setapichange, apichange }) => {
  const [selectedFile, setSelectedFile] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const token = localStorage.getItem("token");

  const handleClose = () => {
    setShow(false);
    setErrorMessage("");
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "text/csv") {
      const reader = new FileReader();
      reader.onload = (e) => {
        const contents = e.target.result;
        const lines = contents.split("\n");
        const isEmpty = lines.every((line) => line.trim() === "");

        if (isEmpty) {
          setErrorMessage("CSV file is empty");
          setSelectedFile(null);
        } else {
          setSelectedFile(file);
          // Process the CSV file further
        }
      };
      reader.readAsText(file);
      setErrorMessage("");
      setSelectedFile(file);
    } else {
      setSelectedFile(null);
      setErrorMessage("Please select a CSV file");
    }
  };
  //File Upload Api
  const fileUploadApi = (token, file) => {
    const response = DataService.UploadFileFund(token, file);
    toast
      .promise(response, {
        pending: "Uploading in process",
      })

      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          swal({
            icon: "success",
            title: "File Upload Successful!",
            text: "Your file has been uploaded successfully.",
            timer: 2000,
            showConfirmButton: false,
          });
          Setapichange(!apichange);
        }
      })
      .catch((error) => {
        if (error) {
          toast.error("somthing went wrong");
          swal({
            icon: "error",
            title: "No File Selected!",
            text: "Please select a file to upload.",
            timer: 2000,
            showConfirmButton: false,
          });
        }
      });
  };

  const handleFileSubmit = () => {
    if (selectedFile && errorMessage.length == 0) {
      const formData = {
        payroll_csv: selectedFile,
      };
      fileUploadApi(token, formData);
      setSelectedFile(null);
      setErrorMessage("");
      handleClose();
    } else if (errorMessage?.length == 0) {
      setErrorMessage("Please select a CSV file");
    }
  };
  //sample file download
  const handleDownload = () => {
    const filePath = `${file}`;
    fetch(filePath)
      .then((response) => response.blob())
      .then((blob) => {
        saveAs(blob, "payroll.csv");
      })
      .catch((error) => {});
  };
  return (
    <>
      <div>
        <Container className="file">
          <FileUploadModelStyle
            show={show}
            onHide={handleClose}
            style={{ padding: "1rem" }}
          >
            <div className="p-4">
              <h>Add funds by uploading the bulk file</h>
              <br />
              <p1
                onClick={handleDownload}
                style={{ textDecoration: "auto", cursor: "pointer" }}
                className="download"
              >
                Download the Sample File
              </p1>
              <div></div>
              <input
                type="file"
                id="fileUpload"
                className="file-upload-input mt-2"
                accept=".csv"
                onChange={handleFileChange}
              />
              {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
              <br />
              <br />
              <h6>Mandatory Columns for File Upload</h6>
              Email, Employee Code, Amount, Transaction Date
            </div>

            <Modal.Footer>
              <SaveButton
                variant="primary"
                className="save-Button"
                onClick={handleFileSubmit}
              >
                Upload
              </SaveButton>
              <CancelButton
                variant="secondary"
                onClick={handleClose}
                className="cancel-button"
              >
                Cancel
              </CancelButton>
            </Modal.Footer>
          </FileUploadModelStyle>
        </Container>
      </div>
    </>
  );
};

export default FileUpload;

const FileUploadModelStyle = styled(Modal)`
  .modal-footer {
    border: none !important;
  }
  .modal-body {
    font-size: 20px;
    position: relative;
    flex: 1 1 auto;
    padding: 0 !important;
    color: #64748b;
    font-weight: 500;
  }
  p {
    font-size: 15px;
    color: #64748b;
  }

  .file-upload {
    display: inline-block;
  }

  .file-upload-label {
    background-color: #f8f9fa;
    padding: 8px 16px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    cursor: pointer;
  }
  .cancel-button:hover {
    color: #64748b;
    width: auto;
  }
  .download {
    cursor: pointer;
    color: ${() => ColorChangerBasedOnId().model.sampleDownload.color};
  }
`;
