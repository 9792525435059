import "./App.css";
import SaasApplication from "./SaasApplication/src/App";
import { Provider } from "react-redux";
import store from "./SaasApplication/src/Store/store";

function App() {
  return (
    <div>
      <Provider store={store}>
        <SaasApplication />
      </Provider>

    </div>
  );
}

export default App;
