import React from "react";
import styled from "styled-components";
import { ColorChangerBasedOnId } from "../../Theme";


// Define a styled component for the footer
const FooterContainer = styled.div`
  padding: 20px;
  text-align: center;
  background-color: ${() => ColorChangerBasedOnId().footer.backgroundColor};
  };
`;

const CopyrightText = styled.p`
  font-size: 14px;
  text-align: center;
  color: ${() => ColorChangerBasedOnId().footer.color};
`;

const Footer = () => {
  return (
    <FooterContainer>
      <CopyrightText>
        &copy; {new Date().getFullYear()}. All rights reserved. Powered by
        Impact Guru Foundation.
      </CopyrightText>
    </FooterContainer>
  );
};

export default Footer;
