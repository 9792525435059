import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import File from "./masterEmployee-demofile.csv";
import { DataService } from '../Services/Dataservice';
import { FaFileUpload } from "react-icons/fa";
import { toast } from 'react-toastify';
import { saveAs } from "file-saver";
import { pagereload } from "../Services/Constant";

const Action2 = ({ show, setLoadForm }) => {
    const token = localStorage.getItem("token");
    const [file ,setFile] = useState(null)
    const [errors, setErrors] = useState('');
    const closeModal = () => {
        setLoadForm({
            'showForm1': false,
            'showForm2': false
        })
    };
    const filehandling = (e) => {
        let file = e.target.files[0];
        console.log(file)
        if (file.length == 0) {
            setErrors('File is Empty');
        } else {
            setFile(file);
        }
    };
    const uploadFile = () => {
        let data = { employee_csv: file };

        try {
            const res = DataService.masterEmployeeCreation(token, data);
            toast.promise(res, {
                pending: "Uploading is in process",
            }).then((response) => {
                if (response?.status == 200) {
                    toast.success("File uploaded successfully");
                    closeModal();
                    setTimeout(()=>{
                        pagereload();
                    },1200)
                }else{
                    toast.error("File upload failed");
                }
            })
        } catch (e) {
            console.log(e);
        }

    };
    //sample file download
    const handleDownload = () => {
        const filePath = `${File}`;
        fetch(filePath)
            .then((response) => response.blob())
            .then((blob) => {
                saveAs(blob, "employee-master-demo.csv");
            })
            .catch((error) => { });
    };
    return <>
        <Modal show={show} >
            <Modal.Header>
                <Modal.Title style={{ fontSize: "20px" }}>Master Employee Addition</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                Submit Your Document.<br /> <p
                    onClick={handleDownload}
                    style={{ textDecoration: "auto", cursor: "pointer",borderBottom:'1.75px solid rgb(21, 114, 156)',width:'fit-content',color:'#15729C' }}
                    className="download"
                > Download the Sample File</p>
                <div >
                    <label role='button' for="csvFileUpload" class="custom-file-upload fw-bold border border-dark p-1 mt-2 rounded pe-auto" >
                        <FaFileUpload className='mb-1' /> Upload Your File
                    </label>
                    <p className="mx-2 file-selected d-inline-block">{file?.name ? file.name : 'No File Selected'}</p>
                    <input name='payroll_csv' className='d-none' type="file" id="csvFileUpload" onChange={filehandling} />
                    {errors.length ? errors : ''}
                </div>

            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" className="mx-3 px-2" onClick={uploadFile} style={{ backgroundColor: 'rgb(21, 114, 156)' }}> Upload </Button>
                <Button variant="secondary" className="mx-3 px-2" onClick={closeModal}> Cancel </Button>
            </Modal.Footer>
        </Modal>
    </>

}
export default Action2;