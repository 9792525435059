import React from "react";
import Table from "react-bootstrap/Table";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import styled from "styled-components";
import { useSelector } from "react-redux";
import theme from "../Theme";

const SkeletonTableStyle = styled.div`
  th {
    background-color: ${(state) =>
      state.domainId === 1
        ? theme.themeColor3
        : state.domainId === 2
        ? `#1f935d !important`
        : ""};
    color: $blue !important;
  }

  .table-striped > tbody > tr:nth-of-type(odd) > * {
    --bs-table-bg-type: none !important;
  }

  .main {
    border: 1px;
    border-style: ridge;
    padding: 1rem;

    @media screen and (max-width: $medium) {
      margin-left: 0.5rem;
    }
  }

  .table-height {
    height: auto !important;
  }
`;

const SkeletonTable = () => {
  const domainId = useSelector((state) => state.DomainDetails.domainId);
  const numRows = 10; // Number of rows

  return (
    <SkeletonTableStyle>
      <div
        className="overflow-auto table-height"
        style={{ height: "28rem !important" }}
      >
        <Table striped bordered hover>
          <thead>
            <tr>
              {[...Array(7)].map((_, index) => (
                <th key={index}></th>
              ))}
            </tr>
          </thead>
          <tbody>
            {[...Array(numRows)].map((_, rowIndex) => (
              <tr key={rowIndex}>
                {[...Array(7)].map((_, cellIndex) => (
                  <td key={cellIndex}>
                    <Skeleton />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </SkeletonTableStyle>
  );
};

export default SkeletonTable;
