import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Employees from "./Pages/Employees/Employees";
import Header from "./Components/Header/Header";
import Campaigns from "./Pages/Campaigns/campaigns";
import Transactions from "./Pages/Transactions/Transactions";
import Payroll from "../src/Pages/Payroll/Payroll";
import LoginPage from "./Pages/LoginPage/LoginPage";
import ResetPasswordPage from "./Pages/ResetPassword/ResetPassword";
import { useSelector } from "react-redux/es/hooks/useSelector";
import GotoTo from "../src/Components/GotoTo/GotoTop";
import NotFound from "../src/Pages/PageNotFound/BackToLogin";
import Footer from "./Components/Footer/Footer";
import SideBarNew from "./Components/SidebarNew/sidebarNew";
import MainHeader from "./Components/MainHeader/MainHeader";
import RebelHomepage from "./Components/HomePage/RebelHomePage/HomePage";
import IgfHomepage from "./Components/HomePage/IgfHomePage/igfHomePage"
import CampaignsDetails from "./Pages/CampaignsDetails/CampaignsDetails";
import SessionLogoutComponent from "./Components/SessionLogoutComponent/SessionLogoutComponent";
import { useDispatch } from "react-redux";
import { saveDomainId, savecurrentDomain } from "./Store/DomainDetails";
import { domainId, currentDomain } from "../src/DomainVariable";
import Reports from "./Pages/Transactions/Transactions";
import Action1 from "./Model/Action1";

const App = () => {
  const dispatch = useDispatch();


  const storeToken = useSelector((state) => {
    return state.token;
  });

  const token = localStorage.getItem("token");

  useEffect(() => {
    // Define different properties based on the domain
    let favicon, title, ogTitle, ogDescription;

    if (domainId === 1) {
      favicon = "/favicon.ico";
      title = "Rebel-Foods";
      ogTitle = "https://rebelfoods.impactgurufoundation.org/";
      ogDescription =
        "Rebel Foods | Empowering Communities through Impact Guru Foundation";
    } else if (domainId === 2) {
      favicon = "/faviconig.ico";
      title = "IGF-testing";
      ogTitle = "Custom Title for AnotherDomain.com";
      ogDescription = "Custom Description for AnotherDomain.com";
    } else {
      // Default values for other domains
      favicon = "/faviconig.ico";
      title = "IGF Testing";
      ogTitle = "Default Open Graph Title";
      ogDescription = "Default Open Graph Description";
    }

    // Update the favicon
    const faviconLink = document.querySelector("link[rel*='icon']");
    if (faviconLink) {
      faviconLink.href = favicon;
    }

    // Update the title
    document.title = title;

    // Update the Open Graph meta tags
    const ogTitleMeta = document.querySelector('meta[property="og:title"]');
    const ogDescriptionMeta = document.querySelector(
      'meta[property="og:description"]'
    );

    if (ogTitleMeta) {
      ogTitleMeta.content = ogTitle;
    }

    if (ogDescriptionMeta) {
      ogDescriptionMeta.content = ogDescription;
    }

    dispatch(saveDomainId({ domainId: domainId }));
    dispatch(savecurrentDomain({ currentDomain: currentDomain }));
  }, []);

  return (
    <div>
      <BrowserRouter>
        {token ? (
          <>
            <Header />
            <SideBarNew>
              <Routes>
                <Route path="/" element={<Payroll />} />
                <Route path="/employees" element={<Employees />} />
                <Route path="/campaigns" element={<Campaigns />} />
                <Route path="/reports" element={<Reports />} />
                <Route path="/campaigns" element={<Campaigns />} />
                <Route
                  path="/campaignsDetails"
                  element={<CampaignsDetails />}
                />
                <Route path="*" element={<NotFound />} />
              </Routes>
              <SessionLogoutComponent />
              <GotoTo />
              <Footer />
            </SideBarNew>
          </>
        ) : (
          <>
            <MainHeader />
            <Routes>
              <Route>
                {domainId === 1 && <Route path="/" element={<RebelHomepage />} />}
                {<Route path="/forgot-password" element={<ResetPasswordPage />} />}
                {domainId === 2 && <Route path="/" element={<IgfHomepage />} />}
                  <Route path="/admin-login" element={<LoginPage />} />
                  <Route path="*" element={<NotFound />} />
              </Route>
            </Routes>
          </>
        )}
      </BrowserRouter>
    </div>
  );
};

export default App;
