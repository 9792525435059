import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import * as formik from 'formik';
import * as yup from 'yup';
import { DataService } from "../Services/Dataservice";
import { toast,ToastContainer } from "react-toastify";
import { pagereload } from "../Services/Constant";

const Action1 = ({ show, setLoadForm }) => {
    let intialValues = {
        name: '',
        email: '',
        emp_id: '',
        gender: '',
        dob: '',
        city: '',
        state: '',
        pincode: '',
        pan: ''
    };
    const [formData, setFormData] = useState(null);
    const token = localStorage.getItem("token");
    const { Formik } = formik;
    const handleEmployeeSubmit = (values, { setSubmitting }) => {

        // Access the form values here :
        setFormData(values);
        intialValues = { name: '', email: '', emp_id: '', gender: '', dob: '', city: '', state: '', pincode: '', pan: '' };
        setSubmitting(false);

        // API CALLING FOR EMPLOYEE CREATION :
        const response = DataService.CampaignCreation(token, values);

        try {
            toast
                .promise(response, { pending: "Waiting for the respose", })
                .then((res) => {
                    toast.success(res.data.message);
                    handleClose();
                    setTimeout(()=>{
                        pagereload();
                    },1200)

                })
                .catch((error) => {
                    if (error) {
                        toast.error("Something went wrong");
                    }
                });

        } catch (e) {
            console.log(e);
        }
    };

    const schema = yup.object().shape({
        name: yup.string().min(3, 'Name should be greater than 3 characters').max(50, ' Name should be less than 50 characters').required('Name is required '),
        email: yup.string().email('Email is not valid').required('Email is required ').matches(/[.]/, 'Email is not valid'),
        mobile: yup.string().min(10, 'Mobile number should be of 10 digits.').max(10, ' Mobile number should be 10 digits.').required(' Mobile Number is required ').matches(/^[0-9]+$/, ('Only Numbers are allowed ')),
        emp_id: yup.string().required('Employee code is required '),
        address: yup.string().min(3, 'Address should be greater than 3 characters').max(150, ' Address should be less than 150 characters').required('Address is required '),
        city: yup.string().strict().required('City is required ').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed  "),
        state: yup.string().strict().required('State is required ').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed  "),
        pincode: yup.string().min(6, ' Pincode should be six digits ').matches(/^[0-9]+$/, ('Only Numbers are allowed ')).required('Pincode is required ').typeError('Only numbers are allowed '),
        pan: yup.string().min(10, ' PAN card should be of ten digits ').max(10, 'PAN card should be of ten digits ').matches(/[A-Z]{5}[0-9]{4}[A-Z]{1}$/, ('PAN card is not valid ')).required('PAN card is required '),
        dob: yup.string().required('DOB is required ').min(10, 'Enter a valid date of birth.').test('is-valid-date', 'Invalid date', (value) => {
            if ((value.length == 10 && value.substring(6) >= new Date().getFullYear()) || (Number(value.substring(0, 2)) > 31) || (Number(value.substring(3, 5)) > 12) || (Number(value.substring(0, 2)) > 31)) {
                return false;
            } else {
                return true;
            }
        }),
        gender: yup.string().required('Gender is required ').test('is-valid-gender', 'Please Select a gender', (value) => {
            if (value == -1) {
                return false;
            } else {
                return true;
            }
        }),
    });

    // Form submission function

    const handleClose = () => setLoadForm({
        'showForm1': false,
        'showForm2': false
    });

    return <>
        <Modal show={show} >
            <Modal.Header>
                <Modal.Title style={{ fontSize: "20px" }}>EMPLOYEE CREATION</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Formik validationSchema={schema} onSubmit={handleEmployeeSubmit} initialValues={intialValues}>
                    {({ handleSubmit, handleChange, values, touched, errors }) => (
                        <Form noValidate onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit()
                        }}>
                            <Row className="mb-2">
                                <Form.Group as={Col} md="12" controlId="validationFormik01">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Your Name"
                                        name="name"
                                        value={values.name}
                                        onChange={handleChange}
                                        isInvalid={!!errors.name}
                                        spellCheck={false}
                                    />
                                    <Form.Control.Feedback type="invalid"> {errors.name}</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-2">
                                <Form.Group as={Col} md="12" controlId="validationFormik02">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Your Email"
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        isInvalid={!!errors.email}
                                        spellCheck={false}
                                    />
                                    <Form.Control.Feedback type="invalid"> {errors.email}</Form.Control.Feedback>
                                </Form.Group>
                            </Row>

                            <Row>
                                <Form.Group as={Col} md="12" controlId="validationFormik04">
                                    <Form.Label>Mobile Number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Your Mobile Number"
                                        name="mobile"
                                        value={values.mobile}
                                        onChange={handleChange}
                                        isInvalid={!!errors.mobile}
                                        maxLength={10}
                                        spellCheck={false}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.mobile}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>

                            <Row>
                                <Form.Group as={Col} md="12" controlId="validationFormik04">
                                    <Form.Label>Employee Code</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Your Employee Code"
                                        name="emp_id"
                                        value={values.employee_code}
                                        onChange={handleChange}
                                        isInvalid={!!errors.emp_id}
                                        spellCheck={false}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.emp_id}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>

                            <Row>
                                <Form.Group as={Col} md="12" controlId="validationFormik04">
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Your Address"
                                        name="address"
                                        value={values.address}
                                        onChange={handleChange}
                                        isInvalid={!!errors.address}
                                        spellCheck={false}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.address}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>

                            <Row className="mb-2">
                                <Form.Group as={Col} md="12" controlId="validationFormik03">
                                    <Form.Label>City</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="City"
                                        name="city"
                                        value={values.city}
                                        onChange={handleChange}
                                        isInvalid={!!errors.city}
                                        spellCheck={false}
                                    />

                                    <Form.Control.Feedback type="invalid">
                                        {errors.city}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>


                            <Row>
                                <Form.Group as={Col} md="12" controlId="validationFormik04">
                                    <Form.Label>State</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="State"
                                        name="state"
                                        value={values.state}
                                        onChange={handleChange}
                                        isInvalid={!!errors.state}
                                        spellCheck={false}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.state}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>


                            <Row>
                                <Form.Group as={Col} md="12" controlId="validationFormik05">
                                    <Form.Label>Pincode</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Your Pincode"
                                        name="pincode"
                                        value={values.pincode}
                                        onChange={handleChange}
                                        isInvalid={!!errors.pincode}
                                        spellCheck={false}
                                    />

                                    <Form.Control.Feedback type="invalid">
                                        {errors.pincode}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>

                            <Row>
                                <Form.Group as={Col} md="12" controlId="validationFormik05">
                                    <Form.Label>PAN Card</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Your PAN Card"
                                        name="pan"
                                        value={values.pan}
                                        onChange={handleChange}
                                        isInvalid={!!errors.pan}
                                        spellCheck={false}
                                    />

                                    <Form.Control.Feedback type="invalid">
                                        {errors.pan}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} xs="5" controlId="validationFormik05">
                                    <Form.Label>Gender</Form.Label>
                                    <Form.Control as="select" name="gender" aria-label="Default select example" isInvalid={!!errors.gender} onChange={handleChange}>
                                        <option value="-1">Select Your Gender</option>
                                        <option value="0">Male</option>
                                        <option value="1">Female</option>
                                        <option value="2">Other</option>

                                    </Form.Control>

                                    <Form.Control.Feedback type="invalid">
                                        {errors.gender}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} xs="7" controlId="validationFormik06">
                                    <Form.Label>DOB</Form.Label>
                                    <Form.Control name="dob" maxLength="10" placeholder="Enter Your DOB (DD/MM/YYYY)" isInvalid={!!errors.dob} onChange={handleChange} onKeyUp={(e) => {
                                        if ((e.key === "Backspace" || e.key === "Delete")) return;
                                        e.target.value = e.target.value.replace(/[^\d-]+/, '');
                                        if (e.target.value?.length == 2 || e.target.value?.length == 5) {
                                            e.target.value = e.target.value + '-';
                                        }
                                    }}
                                    >
                                    </Form.Control>

                                    <Form.Control.Feedback type="invalid">
                                        {errors.dob}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>


                            <Row className="justify-content-end mt-3">
                                <Col xs={8} className="d-flex align-items-center justify-content-end ">
                                    <Button variant="secondary" className="mx-3" onClick={handleClose}> Close </Button>
                                    <Button type="submit" className="ml-4 text-nowrap">Save Changes</Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>


        </Modal>
       
    </>;
};
export default Action1;
