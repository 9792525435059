import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { DataService } from "../../Services/Dataservice";
import { Button, ForgotContainer, ForgotForm, Image, Input, OtpInput } from "./ResetPasswordStyle";
import getImageBasedOnInput from "../../Common/ImageHandler/ImageHandler";
import styled from "styled-components";
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer, toast } from "react-toastify";
import { Stack } from 'react-bootstrap';
import { FaEye } from "react-icons/fa";
const InfoText = styled.p`
  font-size: 15px;
  text-align: left;
  margin-top:7px;
`;
const WarningText = styled.p`
  font-size: 14px;
  text-align: left;
  font-weight:700;
  color:white;
  position:relative;
  top:5px;
`;
const ResetPassword = () => {
    const domainId = useSelector((State) => State.DomainDetails.domainId);
    const [email, setEmail] = useState(null);
    const [emailErr, setEmailErr] = useState(true);
    const [spinner, setSpinner] = useState(null);
    const [otp, setOTP] = useState(new Array(6).fill(""));
    const [otpContainer, setOtpContainer] = useState(false);
    const [updatePassword, setUpdatePassword] = useState(false);
    const [newPasswordUpdated, setNewPasswordUpdated] = useState(false);
    const [showPasswordToUser,setShowPasswordToUser]=useState(false);
    const [showConfirmPasswordToUser,setShowConfirmPasswordToUser]=useState(false);
    const currentDomain = useSelector( (State) => State.DomainDetails.currentDomain);
    const inputRefs = useRef([]);
    const resetPasswordRefs = useRef([]);


    const handleOTPValue = (e, index) => {
        const updatedOtpArray = otp.map((val, i) => {
            if (i == index) {
                return e.target.value;
            }
            else {
                return val;
            }
        })
        setOTP(updatedOtpArray);
    };
    const handleBackspace = (e, i) => {
        // Replace Alphabets :
        e.target.value=e.target.value.replace(/\D$/g,'');
        // Cursor Auto Update :
        if (e.key == "Backspace" && i > 0) {
            inputRefs.current[i - 1].focus();
        } else if (i < otp.length - 1 && e.target.value) {
            inputRefs.current[i + 1].focus();
        }
    };
    const validateEmail = (e) => {
        setEmail(e.target.value);
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (e.target.value == "") {
            setEmailErr("Email is required");
        } else if (!emailRegex.test(e.target.value)) {
            setEmailErr("Enter valid email address!");
        } else {
            setEmailErr(null);
        }
    };
    const handleForm = (e) => {
        e.preventDefault();
        if (emailErr) return;

        setSpinner(true);
        const emailConfirmation = DataService.ForgotPassword({ email,domain:currentDomain});

        try {
            toast.promise(emailConfirmation, {
                pending: 'Sending OTP',
            }).then((response) => {
                let statusCode = response?.data?.statusCode;
                if (statusCode == 400) {
                    toast.info("Email does not exist.")
                } else if (statusCode == 200) {
                    toast.info("OTP Sent ! Please check your email");
                    setOtpContainer(true);
                } else {
                    toast.info("Something went wrong :(")
                }
                setSpinner(false);
            });

        } catch (error) {
            console.log(error);
            toast.error("Failed to send OTP. Please try again later.");
        }
    };


    const handleOtpSubmit = (e) => {
        e.preventDefault();
        const otpNumber = Number(otp.toString().replaceAll(',', ''));
        if (otpNumber.toString().length < 6) {
            return;
        };
        let data = {
            email: email,
            otp: otpNumber
        }
        const otpResponse = DataService.ResetOtpSubmission(data);
        try {
            toast.promise(otpResponse, {
                pending: 'Verifying OTP ',
            }).then((response) => {

                let statusCode = response?.data?.statusCode;
                if (statusCode == 400) {
                    toast.info("OTP is incorrect.")
                } else if (statusCode == 200) {
                    toast.info("OTP Verified !");
                    setOtpContainer(false);
                    setUpdatePassword(true);
                } else {
                    toast.info("Something went wrong :(")
                }

            });

        } catch (e) {
            console.log(e);
        }
    };
    const handleResetPassword = (e) => {
        e.preventDefault();
        const data = new FormData(resetPasswordRefs.current);
        const passwordVal = {};
        if (!data.get('newPassword')?.length || !data.get('confirmNewPassword')?.length) {
            toast.info("Password Shouldn't be empty");
            return;
        } else if (data.get('newPassword') !== data.get("confirmNewPassword")) {
            
            toast.info("New password and confirm password should be same");
            return;
        };
        for (const pair of data.entries()) {
            passwordVal[pair[0]] = pair[1];
        };
        passwordVal['email'] = email;
        const passwordResponse = DataService.confirmNewPassword(passwordVal);
        try {
            toast.promise(passwordResponse, {
                pending: 'Verifying OTP ',
            }).then((response) => {
                let statusCode = response?.data?.statusCode;
                if (statusCode == 400) {
                    toast.info("Password is not valid")
                } else if (statusCode == 200) {
                    toast.info("Your password has been updated successfully !");
                    setNewPasswordUpdated(true);
                } else {
                    toast.info("Something went wrong :(")
                }

            });

        } catch (e) {
            console.log(e);
        }
    }

    return <>

        <ForgotContainer>
            <ForgotForm>
                {!otpContainer && !updatePassword &&
                    <form onSubmit={handleForm}>
                        <Image src={getImageBasedOnInput(domainId)} alt="" />
                        <InfoText>Enter the email address associated with your account.</InfoText>
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <Input onChange={validateEmail} />
                            {emailErr && <WarningText>{emailErr}</WarningText>}
                        </div>
                        <Button type="submit">{spinner ? 'Sending OTP' : 'Send OTP'}
                            {spinner && <Spinner animation="border" role="status" style={{ width: '22px', height: '22px', margin: '0px auto auto 7px' }}></Spinner>}
                        </Button>
                    </form>
                }
                {otpContainer &&
                    <form onSubmit={handleOtpSubmit}>
                        <Image src={getImageBasedOnInput(domainId)} />
                        <InfoText className="pt-1">The OTP has been sent to your email :{email}</InfoText>
                        <Stack direction="horizontal" gap={3}>
                            {otp.map((_, index) => {
                                return <OtpInput key={index} type="text" maxLength={1} ref={(e) => inputRefs.current[index] = e} onChange={(e) => handleOTPValue(e, index)} onKeyUp={(e) => handleBackspace(e, index)} />
                            })}
                        </Stack>
                        <Button type="submit">Submit OTP
                            {spinner && <Spinner animation="border" role="status" style={{ width: '22px', height: '22px', margin: '0px auto auto 7px' }}></Spinner>}
                        </Button>
                    </form>
                }
                {updatePassword &&
                    <>
                        <Image src={getImageBasedOnInput(domainId)} />
                        {newPasswordUpdated ?
                            <p>Your password has been updated successfully. Go to <Link style={{ color: 'white', fontWeight: 600 }} to={'/admin-login'}>Login.</Link></p> :
                            <form onSubmit={handleResetPassword} ref={resetPasswordRefs}>
                                <InfoText>Enter your new password</InfoText>
                                <div className="form-group">
                                    <label htmlFor="password">Password</label>
                                    <div className="d-flex align-items-center">
                                        <Input name="newPassword" type={showPasswordToUser ? "text" :"password"} />
                                        <FaEye style={{position:'relative',right:'25px'}} onClick={()=>setShowPasswordToUser(!showPasswordToUser)} />
                                    </div>
                                    <label className="mt-3" htmlFor="confirmPassword">Confirm Password</label>
                                    <div className="d-flex align-items-center">
                                        <Input name="confirmNewPassword" type={showConfirmPasswordToUser ? "text" :"password"}  />
                                        <FaEye style={{position:'relative',right:'25px'}} onClick={()=>setShowConfirmPasswordToUser(!showConfirmPasswordToUser)}/>
                                    </div>
                                    {emailErr && <WarningText>{emailErr}</WarningText>}
                                </div>
                                <Button type="submit">Reset Password</Button>
                            </form>}
                    </>
                }
                <ToastContainer position="top-right" theme="light" autoClose={2000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            </ForgotForm>
        </ForgotContainer>
    </>

}
export default ResetPassword;