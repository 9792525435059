import rebelloginicon from "./Rebel-Logo-login.png"
import igfloginicone from "./Igf-Logo-login.png"



function getImageBasedOnInput(inputValue) {
    let imageSource;
    // Determine the image source based on the input value
    switch (inputValue) {
      case 1:
        imageSource = rebelloginicon;
        break;
      case 2:
        imageSource = igfloginicone;
        break;
      default:
        imageSource = '/default-image.png'; // Provide a default image for other values
    }
  
    return imageSource
}
export default getImageBasedOnInput