import { configureStore } from '@reduxjs/toolkit';
import tokenReducer from './loginSlice';
import employeesSlice from './employeesSlice';
import DomainDetails from "./DomainDetails"

const store = configureStore({
  reducer: {
    token: tokenReducer,
    employeesDetails:employeesSlice,
    DomainDetails:DomainDetails
  },
});

export default store;