import styled from "styled-components";
import { ColorChangerBasedOnId } from "../Theme";
import Dropdown from 'react-bootstrap/Dropdown';
export const TableContainer = styled.div`
  main {
    margin: "2rem";
  }
  .datestyle {
    width: 165px;
    height: 40px;
    text-align: center;
    color: grey;
    background: #f6f6f6;
    border: 1px solid #c8c8c8;
    border-color: #c8c8c8;
    border-radius: 6px;
  }

  .add-button {
    height: 2rem;
    width: 9rem;
    font-size: 9px;
  }

  @media (max-width: 767px) {
    .input-group {
      width: 100% !important;
    }
    .filtername {
      width: 97px !important;
      height: 38px !important;
    }
  }

  .web {
    .input-group {
      min-width: 400px !important;
    }
    @media (max-width: 767px) {
      display: none !important;
    }
  }

  .mobile {
    .input-group {
      width: 100% !important;
      height: 2.5rem;
    }

    @media (min-width: 768px) and (max-width: 4000px) {
      display: none !important;
    }
  }
`;

export const TableStyle = styled.div`
  th {
    background-color: ${() => ColorChangerBasedOnId().table.th.backgroundColor};
    color: ${() => ColorChangerBasedOnId().table.th.color};
    text-align: center;
  }

  td {
    text-align: center !important;
    word-wrap: break-word !important;
  }

  .table-striped > tbody > tr:nth-of-type(odd) > * {
    --bs-table-bg-type: none !important;
  }

  .main {
    border: 1px;
    border-style: ridge;
    padding: 1rem;

    @media screen and (max-width: 1020px) {
      margin-left: 0.5rem;
    }
  }
  .icon-download {
    color: ${() => ColorChangerBasedOnId().table.icone.color};
    font-size: 20px;
    
  }

  .icon-download:hover {
    font-size: 25px;
  }

  .icone-size {
    width: 1.25rem;
    height: 1.25rem;
  }
  @media (min-width: 768px) and (max-width: 4000px) {
    .mobile {
      display: none !important;
    }
  }
`;

export const TableHeight = styled.div`
  .table-height {
    height: 70vh !important;
  }

  .table-height-auto {
    height: 60rem !important;
  }
`;

export const Addfund = styled.p`
  margin: 1rem;
  width: 149px;
  height: 40px;
  text-align: center;
  color: ${() => ColorChangerBasedOnId().table.addButton.color};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  background-color: ${() =>
    ColorChangerBasedOnId().table.addButton.backgroundColor};
`;

export const FilterButton = styled.button`
  width: 128px;
  height: 40px;
  border: none;
  text-align: center;
  color: ${() => ColorChangerBasedOnId().table.filterButton.color};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  font-size: 15px;
  box-shadow: ${() => ColorChangerBasedOnId().table.filterButton.boxShadow}
    4.95px 3.95px 3.6px !important;
  &:hover,&:active{
     color: ${() => ColorChangerBasedOnId().table.filterButton.color};
      
    }
  &.show,
  &:first-child:active,
  &:not(.btn-check) + &.btn:active {
    color: inherit; /* or 'initial' or 'unset' as needed */
    background-color: transparent;
    border-color: transparent;
  }
`;


export const CustomDropDown = styled(Dropdown)`
  button {
    min-height: 40px !important;
    background-color: #F0F0F0;
    color: ${() => ColorChangerBasedOnId().table.filterButton.color};
    border: none;
    font-weight: 600;
    box-shadow: ${() => ColorChangerBasedOnId().table.filterButton.boxShadow} 4.95px 3.95px 3.6px !important;
  }

  &:hover {
    background-color: #F0F0F0 !important;
    min-height: 40px !important;
    color: ${() => ColorChangerBasedOnId().table.filterButton.color};
    border: none;
    box-shadow: ${() => ColorChangerBasedOnId().table.filterButton.boxShadow} 4.95px 3.95px 3.6px !important;
  }
`;
