import { useEffect } from "react";
import Table from "react-bootstrap/Table";
import { AiFillEdit } from "react-icons/ai";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useState } from "react";
import EmployeeModal from "../../Model/EmployeeModel";
import SkeletonTable from "../../Common/SkeletonTable";
import Pagination from "../Pagination/Pagination";
import { DataService } from "../../Services/Dataservice";
import { toast } from "react-toastify";
import Dropdown from 'react-bootstrap/Dropdown';
import { TableStyle, FilterButton, TableHeight, CustomDropDown } from "../../Common/tableStyled";
import Action1 from "../../Model/Action1";
import Action2 from "../../Model/Action2";

function EmpTable({ callApi, setCallApi }) {
  const [employees, setEmployees] = useState(null);
  const [searchvalue, SetSearchvalue] = useState("");
  const [show, setShow] = useState(false);
  const [data, setdata] = useState({});
  const [loading, setLoading] = useState(true);
  const [togal, setTogal] = useState(true);
  const token = localStorage.getItem("token");
  const [isLoading, setIsLoading] = useState(true);
  const [searial, setSerial] = useState(null);
  const [page, setPage] = useState(1);
  const [loadForm, setLoadForm] = useState({
    'showForm1': false,
    'showForm2': false
  });

  const handleDropdownAction = (event) => {
    if(event=='action1'){
      setLoadForm({
        ...loadForm,
        'showForm1': true,
       
      })
    }else{
      setLoadForm({
        ...loadForm,
        'showForm2': true
      })
    }

  };
  
  const handleEdite = (data) => {
    setdata(data);
    setShow(true);
  };
  
  const handlePrevClick = () => {
    if (page - 1 > 0) setPage((prevPage) => prevPage - 1);
    return null;
  };

  const handleNextClick = () => {
    if (page + 1 <= employees?.last_page) setPage((prevPage) => prevPage + 1);
    return null;
  };

  const handlePageClick = (e) => {
    setPage(parseInt(e.target.innerText, 10));
  };

  /* Employee listing API call */
  const EmployeeApi = (token, page, searchvalue) => {
    setIsLoading(true);

    const payrollData = DataService.EmployeeLisList(token, page, searchvalue);

    toast
      .promise(payrollData, {
        pending: "Waiting for the respose",
      })

      .then((res) => {
        if (res) {
          setLoading(false);
          setEmployees(res?.data?.data);
          setSerial(res?.data?.data?.from);
          setPage(res?.data?.data?.current_page);
          if (res?.data?.data?.current_page > 1) {
            toast.success("Updated successfully");
          }
        } else {
          toast.error("Somthing went wrong");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        if (error) {
          toast.error(error?.message);
        }
        setIsLoading(false);
      });
  };

  // search Api call
  const EmployeeSearchApi = (token, data) => {
    setIsLoading(true);
    const payrollData = DataService.EmployeeSearch(token, data);

    toast
      .promise(payrollData, {
        pending: "Waiting for the respose",
      })

      .then((res) => {
        if (res) {
          setLoading(false);
          setEmployees(res?.data?.data);
          setPage(res?.data?.data?.current_page);
          toast.success("Updated successfully");
        } else {
          toast.error("Somthing went wrong");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        if (error) {
          toast.error(error?.message);
        }
        setIsLoading(false);
      });
  };

  const exportData=()=>{

    const EmpExportData = DataService.EmployeeExport(token);
    toast
    .promise(EmpExportData, {
      pending: "Waiting for the respose",
    })
    .then((res)=>{
    
      if (res) {
        toast.success(res?.data?.message);
      } else {
        toast.error("Somthing went wrong");
      }
    })
    .catch((error)=>{
      if (error) {
        toast.error(error?.message);
      }
    })
  }
  const handleFilterClick = () => {
    if (togal) {
      EmployeeSearchApi(token, searchvalue);
    } else if (!togal) {
      SetSearchvalue("");
      EmployeeSearchApi(token, "");
    }
    setTogal(!togal);
  };

  
  useEffect(() => {
    EmployeeApi(token, page, searchvalue);
  }, [page, callApi]);

  return (
    <>
    {loading ? (
        <SkeletonTable />
      ) : (
        <>
          <div className="main pt-3" id="Employeetable">
            <div className="d-flex m-3 justify-content-between">
              <div className="w-100 d-flex">
                <InputGroup size="sm" className="mb-3" style={{ width: "40%", height: "2.5rem" }}>
                  <Form.Control
                    value={searchvalue}
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    placeholder="Search by Employee Phone Number/ Id / Name"
                    style={{ background: "#F2F2F2" }}
                    onChange={(e) => SetSearchvalue(e.target.value)}
                  />
                </InputGroup>

                <FilterButton
                  onClick={handleFilterClick}
                  className="filtername"
                  style={{ marginLeft: "1rem" }}
                >
                  {togal ? "Apply Filter" : "Clear"}
                </FilterButton>

                <FilterButton
                  onClick={exportData}
                  className="filtername"
                  style={{ marginLeft: "1rem" }}
                >
                  {"Export Data"}
                </FilterButton>
              </div>
              <div>
                
                <CustomDropDown onSelect={handleDropdownAction}>
                  <Dropdown.Toggle id="dropdown-basic">
                    Add Employee
                  </Dropdown.Toggle>
                  
                  <Dropdown.Menu>
                    <Dropdown.Item eventKey="action1" > Create Profile</Dropdown.Item>
                    <Dropdown.Item eventKey="action2" > Bulk Create </Dropdown.Item>
                  </Dropdown.Menu>
                </CustomDropDown>
              </div>
            </div>
            <TableHeight>
              <div
                className={`overflow-auto ${employees?.data?.length < 5
                  ? "table-height"
                  : "table-height-auto"
                  } `}
              >
                <TableStyle>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Employee Code</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone No.</th>
                        <th>Donation Amount</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {employees?.data?.map((employeelist, index) => {
                        return (
                          <tr key={index}>
                            <td>{searial + index}</td>
                            <td>{employeelist?.employee_code}</td>
                            <td>
                              {employeelist?.firstname} {employeelist?.lastname}
                            </td>
                            <td>{employeelist?.email}</td>
                            <td>{employeelist?.mobile}</td>
                            <td>{employeelist?.total_donation}</td>
                            <td>{employeelist?.status}</td>
                            <td className="d-flex  justify-content-around">
                              <div>
                                <AiFillEdit
                                  className="edite"
                                  onClick={() => {
                                    handleEdite(employeelist);
                                  }}
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </TableStyle>
             
                <Pagination

                  data={employees}
                  page={page}
                  handlePageClick={handlePageClick}
                  handlePrevClick={handlePrevClick}
                  handleNextClick={handleNextClick}
                  isLoading={isLoading}
                />
              </div>
            </TableHeight>
          </div>
          <EmployeeModal
            show={show}
            setShow={setShow}
            data={data}
            setCallApi={setCallApi}
            callApi={callApi}
          />

        </>
      )};
      <Action1 show={loadForm['showForm1']} setLoadForm={setLoadForm} />
      <Action2 show={loadForm['showForm2']}  demo="demo" setLoadForm={setLoadForm} />
    </>
  );
}

export default EmpTable;
