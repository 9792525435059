import { styled } from "styled-components";
import { ColorChangerBasedOnId } from "../Theme";

export const CardBody = styled.div`
  .card-body {
    box-shadow: 0px 1px 17px rgba(0, 0, 0, 0.1),
      0px 1px 2px -1px rgba(0, 0, 0, 0.1);
    border: none;
    padding-top: 2rem;
    border-radius: 10px;
  }
  .card {
    border: none !important;
  }
  .moneyicondiv {
    padding-left: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 14px;
    background: ${() => ColorChangerBasedOnId().Cards.backgroundColor};
    margin-top: 10px;
    border-radius: 11px;
    margin-right: 1rem;
    .moneyicon {
      font-size: 20px;
      color: ${() => ColorChangerBasedOnId().Cards.icone.color};
    }
  }

  .count {
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 4rem;
    color: ${() => ColorChangerBasedOnId().Cards.color};
  }
  .heading {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    color: ${() => ColorChangerBasedOnId().Cards.color};
  }

  .cardemployee {
    width: 20rem;
    height: 191px;
    @media screen and (max-width: 1024px) {
      width: 100%;
      margin-top: 2rem;
    }


    
  }

  .cardeFund {
    width: 31rem;
    @media screen and (max-width: 1024px) {
      width: 100%;
      margin-top: 2rem;
    }
    .fundCalender{
      font-size:0.8rem;
      height: 30px;
      text-align: center;
      color: grey;
      background: #f6f6f6;
      border: 1px solid #c8c8c8;
      border-color: #c8c8c8;
      border-radius: 6px;
    }
    .fundButton{
      height: 30px;
    border: none;
    text-align: center;
    color: ${() => ColorChangerBasedOnId().Cards.color};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-weight: 600;
    cursor: pointer;
    font-size: 15px;
    box-shadow: 0px 1px 17px rgba(0, 0, 0, 0.1),
    0px 1px 2px -1px rgba(0, 0, 0, 0.1);
    background: #F0F0F0;
    }
  }
  .formselect {
    width: 7rem;
    font-size: 14px;
    padding-top: 2px;
    padding-bottom: 2px;
  }
`;
