import { createSlice } from '@reduxjs/toolkit';

const DomainSlice = createSlice({
  name: 'DomainDetails',
  initialState: {
    currentDomain: null,
    domainId: null,
  },
  reducers: {
    saveDomainId: (state, action) => {
      state.domainId = action.payload.domainId;
    },
    savecurrentDomain:(state ,action)=>{
      state.currentDomain= action.payload.currentDomain
    }
  },
});

export const { saveDomainId ,savecurrentDomain} = DomainSlice.actions;

export default DomainSlice.reducer;