import { Button } from "react-bootstrap";
import styled from "styled-components";
import { ColorChangerBasedOnId } from "../Theme";

export const SaveButton=styled(Button)`
background:${() => ColorChangerBasedOnId().model.saveButton.backgroundColor};

`

export const CancelButton=styled(Button)`
background:${() => ColorChangerBasedOnId().model.cancelButton.backgroundColor};

`