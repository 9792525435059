import styled from "styled-components";
import { ColorChangerBasedOnId } from "../../Theme";

// Styled components :

export const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: ${() =>
    ColorChangerBasedOnId().loginPage.loginContainer.backgroundColor};
`;

export const LoginForm = styled.div`
  width: 300px;
  padding: 20px;
  background-color: #f2f2f2;
  border-radius: 5px;
  background: ${(state) =>
    ColorChangerBasedOnId().loginPage.loginForm.backgroundColor};
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 5px;
`;

export const Input = styled.input`
  width: 100%;
  padding: 5px;
  border-radius: 3px;
  border: 1px solid #ccc;
  background: ${(state) =>
    ColorChangerBasedOnId().loginPage.input.backgroundColor};
  color: #ffffff;
  &::placeholder {
    color: #ffffff; /* Set the desired color */
  }
`;

export const Button = styled.button`
  display: block;
  width: 100%;
  padding: 10px;
  background-color: ${(state) =>
    ColorChangerBasedOnId().loginPage.button.backgroundColor};
  color: ${(state) => ColorChangerBasedOnId().loginPage.button.color};
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  margin-top: 1rem;
`;

export const Error = styled.div`
  p {
    color: red;
    margin-top: 10px;
    font-size: 10px;
  }
`;

export const Image = styled.img`
  width: 100%;
`;

export const ForgotText=styled.p`
  &:after{
    content:'?';
  }
  font-size: 15px;
  margin-top:4px;
  color:white;
  font-weight:600;
`;
